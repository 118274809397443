import { Button, DatePicker, Divider, Empty, Input, Modal, Select } from "antd";
import EmptyIcon from "../../../assets/images/empty-icon.png";
import React from "react";
import moment from "moment";
import dayjs from "dayjs";

const { Option } = Select;
const { TextArea } = Input;

export default function DcInboundOrderFormPo({
  history,
  visibleDeletePo,
  addList,
  noted,
  addListPo,
  onClickAutoGenerateMR,
  onCancelPoDelete,
  onClickAddListPo,
  onClickDeletePo,
  onChangeNoted,
  onChangeSelectPo,
  onChangePoCode,
  onChangePoSubmitDate,
  onChangePoDate,
  onAcceptDeletPo,
}) {
  function getContractList(lists) {
    let contractList = [];

    lists.forEach((item) => {
      item.contract_list.forEach((list) => {
        let isExist = contractList.findIndex((contract) => contract && contract.id === item.contract_id);
        if (list.id === item.contract_id && isExist === -1) {
          contractList.push(list);
        }
      });
    });

    return contractList;
  }

  function DisplayAutoCreateMr() {
    const contarctList = getContractList(addList);
    const isOneAdded = addListPo.length === 1 && !addListPo[0].manufacture_request_id;
    const Mrs = [];
    addListPo.map((po) => {
      if (po.manufacture_request_id) {
        Mrs.push(po);
      }
    });

    return isOneAdded || Mrs.length !== contarctList.length ? (
      <section className="flex-column flex-md-row auto-create-mr-container px-4 py-2">
        <div className="row align-items-center justify-content-center text-left pr-md-2">
          <div className="flex-1">
            <i className="icon-info large mr-0 mr-md-2" />
          </div>
          <div className="text-center">
            เมื่อราคาขาขายเสร็จสมบูรณ์ และราคาขาซื้อระบุสัญญาแล้วจะสามารถกดเพิ่มสร้างใบสั่งจ้างผลิตอัตโนมัติได้
          </div>
        </div>
        <div className="row align-items-center px-3 py-1">
          <Button ghost="true" type="primary" onClick={onClickAutoGenerateMR}>
            สร้างใบสั่งจ้างผลิต
          </Button>
        </div>
      </section>
    ) : (
      ""
    );
  }

  function DisplayPo(po, index) {
    const contractList = getContractList(addList);
    return (
      <section className="card-box p-3 mt-2" index={index}>
        <div className="row">
          <div className="col-md-2 col-4 pr-0 mt-2 text-bold">วันที่ส่งข้อมูล PO</div>
          <div className="col-md-4 col-8 mt-2">
            {console.log("po.po_date", po.po_date)}
            <DatePicker
              format={"DD-MM-YYYY"}
              value={po.submit_date && dayjs(po.submit_date)}
              className="po-date-picker date-picker-w-100"
              onChange={(e) => onChangePoSubmitDate(e, po.index)}
            />
          </div>
          <div className="col-md-2 col-4 pr-0 mt-2 text-bold">
            เลขที่สัญญา <span className="require-mark">*</span>
          </div>
          <div className="col-md-4 col-8 mt-2">
            <Select
              value={po.id}
              className="po-date-picker date-picker-w-100"
              placeholder="เลขที่สัญญา..."
              onChange={(e) => onChangeSelectPo(e, po.index)}
            >
              {contractList.map((listItem) => {
                let selectValue = listItem.id;
                let isSelected = addListPo.find((po) => po.id === selectValue);
                return (
                  <Option value={selectValue} disabled={isSelected ? true : false}>
                    {listItem.code} - {listItem.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 col-4 pr-0 mt-2 text-bold">เลขที่ PO</div>
          <div className="col-md-4 col-8 mt-2">
            <Input value={po.po_code} className="po-date-picker" onChange={(e) => onChangePoCode(e, po.index)} />
          </div>
          <div className="col-md-2 col-4 pr-0 mt-2 text-bold">วันที่ได้รับ PO</div>
          <div className="col-md-4 col-8 mt-2">
            <DatePicker
              format={"DD-MM-YYYY"}
              value={po.po_date && dayjs(po.po_date)}
              className="po-date-picker date-picker-w-100"
              onChange={(e) => onChangePoDate(e, po.index)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 col-4 pr-0 mt-2 text-bold">ใบสั่งจ้างผลิต</div>
          <div className="col-md-4 col-8 mt-2">
            {po.manufacture_request_id ? (
              <div
                style={{ color: "#1890ff", cursor: "pointer" }}
                onClick={() =>
                  history.push({
                    pathname: `/manufacture_requests/${po.manufacture_request_id}`,
                  })
                }
              >
                คลิกเพื่อเปิดดูใบสั่งจ้างผลิต
              </div>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div className="po-warper-footer">
          <Button className={`detete-btn`} danger onClick={() => onClickDeletePo(po.index)}>
            {/* disabled={addListPo.length <= 1 && po.manufacture_request_id === null} */}
            {/* className={`detete-btn ${addListPo.length <= 1 && po.manufacture_request_id === null && "disabled-btn"}`} */}
            DELETE
          </Button>
        </div>
      </section>
    );
  }

  function DisplayRemark() {
    return (
      <div class="p-0">
        <h5 className="pb-1 mb-0">ข้อมูลอื่น ๆ</h5>
        <div className="card-box p-3 mt-1">
          <span>Note : </span>
          <TextArea value={noted} onChange={onChangeNoted} autoSize={{ minRows: 3, maxRows: 3 }} />
        </div>
      </div>
    );
  }

  function DisplayDeletePo() {
    return (
      <Modal
        open={visibleDeletePo}
        centered
        footer={false}
        closable={false}
        style={{ minWidth: "auto" }}
        onCancel={onCancelPoDelete}
      >
        <div className="po-delete-container">
          <div className="po-delete-title">ลบ PO ย่อย</div>
          <Divider style={{ margin: 10 }} />
          <div className="po-delete-content">
            <div className="po-delete-detail">ต้องการลบ PO ย่อยใช่หรือไม่ ?</div>
            <div className="po-delete-detail">ถ้าลบแล้ว ใบสั่งจ้างผลิตที่ Link อยู่จะถูก unlink อัตโนมัติ</div>
          </div>
          <div className="po-delete-footer">
            <Button className="delete-po-accept-btn" onClick={onAcceptDeletPo}>
              ใช่, ลบ Po
            </Button>
            <Button className="delete-po-cancel-btn" onClick={onCancelPoDelete}>
              ยกเลิก
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  const contractsList = getContractList(addList);

  return (
    <section className="pb-3 pb-md-4 px-3 px-md-4 po-container">
      {addList.length > 0 ? (
        <>
          <section>{DisplayAutoCreateMr()}</section>
          <div className="px-0 block-info">
            <section>
              <h5 className="pb-1 mb-0">PO ย่อย</h5>
              {addListPo.length > 0 ? (
                addListPo.map((po, index) => {
                  return DisplayPo(po, index);
                })
              ) : (
                <section className="card-box p-3 mt-2">
                  <Empty description="ไม่มีข้อมูล PO ย่อย" />
                </section>
              )}
              <div className="text-right">
                <Button
                  className={`add-btn ${contractsList.length === addListPo.length && "disabled-btn"}`}
                  disabled={contractsList.length === addListPo.length}
                  onClick={onClickAddListPo}
                >
                  ADD
                </Button>
              </div>
            </section>
          </div>
        </>
      ) : (
        <div className="empty-content">
          <img src={EmptyIcon} width={50} height={50} />
          <span>กรุณาเลือกสินค้า</span>
        </div>
      )}

      <section>{DisplayRemark()}</section>
      <section>{DisplayDeletePo()}</section>
    </section>
  );
}
