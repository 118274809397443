import { Button, Input, Select, InputNumber, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import _ from "lodash";
import ProductReviced from "../../global/ProductReviced";
import EmptyIcon from "../../../assets/images/empty-icon.png";
import moment from "moment";

const { TextArea } = Input;
const { Option } = Select;

export default function DcInboundOrderFormReceived({ order_code, onDeleteLotID, addList, onAddReceivedItem, addReceivedItems, current_user, onDeleteLotNoneID, onChangeQTYAddedLot, fullData }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedQTY, setSelecteQTY] = useState(null);
  const [remark, setRemark] = useState(null);
  const [recivedDate, setRecivedDate] = useState(moment());
  const [productList, setProductList] = useState([]);
  const [selectedItemValue, setSelectedItemValue] = useState(null);
  const [summarizeItem, setSummarizeItem] = useState([]);
  useEffect(() => {
    getProduct().then(() => {
      getSummarizeItem();
    });
  }, [addReceivedItems, addList]);

  async function getSummarizeItem() {
    let summarizeItem = _.chain(addReceivedItems)
      .groupBy("in_bound_id")
      .map((value, key) => {
        let all = 0;
        try {
          all = _.find(fullData.inbound_order_items, (r) => {
            return r.in_bound_id === Number(key);
          }).qty;
        } catch (e) {
          //console.log(e);
        }
        let qty = 0;
        try {
          qty = _.sumBy(value, (sumVal) => {
            return sumVal.qty;
          });
        } catch (e) {
          //console.log(e);
        }
        let remain = all - qty;
        return {
          in_bound_id: key,
          data: value,
          name: value[0] ? (value[0].name ? value[0].name : "-") : "-",
          qty: qty,
          all: all,
          remain: remain,
          finished: remain === 0 ? "mdi mdi-check-circle status-green" : "",
        };
      })
      .value();
    let data = _.map(fullData.inbound_order_items, (item) => {
      let all = _.find(summarizeItem, (r) => {
        return Number(r.in_bound_id) === Number(item.in_bound_id);
      });
      let obj = {};
      obj.in_bound_id = item.in_bound_id;
      obj.name = item.name;
      obj.qty = item.qty;
      obj.all = all ? all.qty : 0;
      obj.remain = all ? item.qty - all.qty : item.qty;
      obj.unit = item.unit;
      obj.finished = obj.remain == 0 ? "mdi mdi-check-circle status-green" : "";
      return obj;
    });

    getProduct();
    setSummarizeItem(data);
    return await data;
  }

  async function getProduct() {
    let itemList = [];
    let newList = [...addList];

    newList.forEach((items) => {
      items.item_category.forEach((itemCate) => {
        let findAddedRevicedItems = addReceivedItems.filter((recived) => recived.in_bound_id === itemCate.in_bound_id);
        let reduceItemCate = { ...itemCate };

        findAddedRevicedItems.forEach((addedItem) => {
          reduceItemCate.qty = reduceItemCate.qty - addedItem.qty;
        });

        if (itemCate.qty) itemList.push(reduceItemCate);
      });
    });

    await setProductList(itemList);
    return await itemList;
  }

  function onChangeSelectedProduct(e) {
    let selectedProduct = productList.find((product) => product.id === e);
    setSelecteQTY(null);
    setSelectedItem(selectedProduct);
    setSelectedItemValue(e);
  }

  function onAddRecivedItem() {
    const productIndex = productList.findIndex((product) => product.id === selectedItem.id);
    const reduceProduct = [...productList];

    if (selectedQTY !== null && reduceProduct[productIndex].qty > 0 && productList[productIndex].qty >= selectedQTY) {
      reduceProduct[productIndex].qty = productList[productIndex].qty - selectedQTY;
      onAddReceivedItem(reduceProduct[productIndex], selectedQTY, remark, recivedDate);

      setRemark(null);
      setSelecteQTY(null);
      setSelectedItemValue(null);
      setSelectedItem(null);
      setRecivedDate(moment());
    }
  }

  function onChangeItemQTY(e) {
    setSelecteQTY(e === null ? 1 : e);
  }

  function onChangeRecivedDate(e) {
    setRecivedDate(moment(e));
  }

  function onChangeRemark(e) {
    setRemark(e);
  }

  function DisplayHeaderRecived() {
    return (
      <section className="card-box p-3">
        <div className="row">
          <div className="col-md-2 col-4 pr-0 mt-2 text-bold">
            สินค้า <span className="require-mark">*</span>
          </div>
          <div className="col-md-4 col-8 mt-2">
            <Select value={selectedItemValue} className="po-date-picker date-picker-w-100" placeholder="เลือกสินค้า..." onChange={(e) => onChangeSelectedProduct(e)}>
              {productList.map((item, index) => {
                return (
                  item.qty > 0 && (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  )
                );
              })}
            </Select>
          </div>
          <div className="col-md-2 col-4 pr-0 mt-2 text-bold">
            จำนวน <span className="require-mark">*</span>
          </div>
          <div className="col-md-3 col-6 mt-2">
            <InputNumber value={selectedQTY} min={1} max={selectedItem && selectedItem.qty} disabled={!selectedItem} controls={false} stringMode={false} className="po-date-picker" onChange={(e) => onChangeItemQTY(e)} />
          </div>
          <div className="col-md-1 col-2 mt-2 px-0" style={{ display: "flex", alignItems: "center" }}>
            {selectedItem && `/ ${selectedItem.qty} ${selectedItem.unit}`}
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 col-4 pr-0 mt-2 text-bold">
            วันที่ตรวจรับ <span className="require-mark">*</span>
          </div>
          <div className="col-md-4 col-8 mt-2">
            <DatePicker value={recivedDate} format={"DD-MM-YYYY"} disabled={!selectedItem} className="po-date-picker" onChange={(e) => onChangeRecivedDate(e)} />
          </div>
          <div className="col-md-2 col-4 pr-0 mt-2 text-bold">หมายเหตุ</div>
          <div className="col-md-3 col-6 mt-2">
            <TextArea disabled={!selectedItem} value={remark} autoSize={{ minRows: 3, maxRows: 3 }} onChange={(e) => onChangeRemark(e.target.value)} />
          </div>
        </div>
        <div className="po-warper-footer">
          <Button className={`add-btn ${!selectedItem && "disabled-btn"}`} disabled={!selectedItem} onClick={onAddRecivedItem}>
            ADD
          </Button>
        </div>
      </section>
    );
  }

  function RevicedTableHeader() {
    return (
      <div className="card-box px-3 py-2 mt-3 my-2 d-none d-md-block text-bold">
        <div className="row">
          <div className="col-12 d-flex flex-row align-items-center">
            <div className="col-custom-4 col-custom-sm-100">ลำดับ</div>
            <div className="col-custom-26 col-custom-sm-100">รายการ</div>
            <div className="col-custom-10 col-custom-sm-100 justify-content-center">จำนวน</div>
            <div className="col-custom-10 col-custom-sm-100 justify-content-center">หน่วย</div>
            <div className="col-custom-12 col-custom-sm-100">วันที่ตรวจรับ</div>
            <div className="col-custom-12 col-custom-sm-100">ผู้ตรวจรับ</div>
            <div className="col-custom-14 col-custom-sm-100">หมายเหตุ</div>
            <div className="col-custom-12 col-custom-sm-100"></div>
          </div>
        </div>
      </div>
    );
  }

  function RevicedTableItem() {
    const groupItems = _.groupBy(addReceivedItems, "item_category_id");
    return Object.keys(groupItems).map((key) => {
      const findCategoty = addList.find((cate) => cate.id === parseInt(key));
      return (
        <section>
          <div className="table-item">
            <text className="table-item-name pl-2">{findCategoty ? findCategoty.sys_name : "-"}</text>
          </div>
          <div className="card-box px-3 py-2 mb-2 d-block">
            <div className="row">
              {groupItems[key].map((item, index) => {
                return <ProductReviced order_code={order_code} onChangeQTYAddedLot={onChangeQTYAddedLot} item={item} index={index + 1} currentUser={current_user} onDeleteLotNoneID={onDeleteLotNoneID} onDeleteLotID={onDeleteLotID} />;
              })}
            </div>
          </div>
        </section>
      );
    });
  }

  function DisplayEmpty() {
    return (
      <div className="empty-content">
        <img src={EmptyIcon} width={50} height={50} />
        <span>กรุณาเลือกสินค้า</span>
      </div>
    );
  }

  function DisplaySummarizeHeader() {
    return (
      <div className="card-box px-3 py-2 mt-3 my-2  text-bold">
        <table class="table table-striped table-mobile-responsive">
          <thead>
            <tr>
              <th scope="col">ลำดับ</th>
              <th scope="col">สินค้า</th>
              <th scope="col">จำนวน</th>
              <th scope="col">ตรวจรับแล้ว</th>
              <th>ยังไม่ได้ตรวจรับ</th>
              <th></th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }

  function DisplaySummarizeItem() {
    return (
      <div className="card-box px-3 py-2 mt-3 my-2  text-bold">
        <table class="table table-striped table-mobile-responsive table-mobile-sided">
          <thead>
            <tr>
              <th scope="col">ลำดับ</th>
              <th scope="col">สินค้า</th>
              <th scope="col">จำนวน</th>
              <th scope="col">ตรวจรับแล้ว</th>
              <th>ยังไม่ได้ตรวจรับ</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {summarizeItem.map((item, index) => {
              return (
                <tr>
                  <td data-content="ลำดับ">{index + 1}</td>
                  <td data-content="สินค้า">{item.name}</td>
                  <td data-content="จำนวน">
                    {item.qty} {item.unit}
                  </td>
                  <td data-content="ตรวจรับแล้ว">
                    {item.all} {item.unit}
                  </td>
                  <td data-content="ยังไม่ได้ตรวจรับ">
                    {item.remain} {item.unit}
                  </td>
                  <td data-content="">
                    <span class={item.finished}></span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <section className="pb-3 pb-md-4 px-3 px-md-4 recived-container">
      <section>
        <h4>สรุปการตรวจรับสินค้า</h4>
        {/* {DisplaySummarizeHeader()} */}
        {DisplaySummarizeItem()}
      </section>
      <section>{DisplayHeaderRecived()}</section>
      <section>
        {RevicedTableHeader()}
        {addReceivedItems.length > 0 ? RevicedTableItem() : DisplayEmpty()}
      </section>
    </section>
  );
}
