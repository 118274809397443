import React, { useState, useRef } from "react";
import { DatePicker, Select, Input, Button, Modal, Tooltip, InputNumber, Form, Divider } from "antd";
import moment from "moment";
import EmptyIcon from "../../../assets/images/empty-icon.png";
import Spinner from "../../global/Spinner.js";
import AddItemModal from "./AddItemModal";
import { solveFeeNew, solveFee, FEE_MULTIPLIER } from "../../../functions/FeePurchaseCalculation";
import { saveMultiple } from "../../../functions/Number";
const { Option } = Select;
const { TextArea } = Input;

const disable_input_status = ["pending_delivery", "partially_received", "received"];

export default function DcInboundOrderFormProductList({
  inbound_data,
  order_data,
  addList,
  isLoading,
  categoryGroup,
  noted,
  resetFees,
  approveDate,
  onSubmitAddItem,
  onSelectItemToAdd,
  onChangeQtyItem,
  onChangeContract,
  onSubmitMultiSelected,
  onClickDeleteItem,
  onClickDeleteItemFee,
  onClickDeleteCategotyFee,
  onChangeItemFeeQTY,
  onChangeCategotyFeeQTY,
  onChangeDiscount,
  onSubmitAddExpense,
  onDeleteAddExpense,
  onChangeExpRatio,
  onChangePriceAddExpense,
  onChangeQTYAddExpense,
  onChangeCategotyFeePrice,
  onChangeItemFeePrice,
  onChangeApproveDate,
  onChangeNoted,
  handelChangeItemPrice,
  handelChangeItemQTY,
}) {
  const [expenseModal, setExpenseModal] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [qtyAdded, setQTYAdded] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [expanAddModal, setExpanAddModal] = useState(false);
  const [multiSelect, setMultiSelect] = useState([]);
  const [isResetMultipleSelect, setIsResetMultipleSelect] = useState(false);
  const [isOpenAddItemResponsive, setIsOpenAddItemResponsive] = useState(false);
  const [currentSelectAddExpense, setCurrentSelectAddExpense] = useState(null);
  const [addExpenseQTY, setAddExpenseQTY] = useState(0);
  const [addExpensePrice, setAddExpensePrice] = useState(0);
  const [resetName, setResetName] = useState("");
  const [resetID, setResetID] = useState(0);
  const [isModalReset, setIsModalReset] = useState(false);
  const [isChangeApproveDate, setIsChangeApprovedDate] = useState(false);
  const [itemDiscountList, setItemDiscountList] = useState([]);

  const formRef = useRef();

  function removeComma(str) {
    return str.replace(/,/g, "");
  }

  function DisplayItemList(categories) {
    let item_index = -1;
    let expRatio = [];

    return categories.length > 0 ? (
      <div className="table-container-items">
        {categories.map((category) => {
          let sumFees = getSumFees(category);
          let itemShowList = [];
          let itemQTYList = category.item_category.map((item) => item.qty);
          let itemPriceList = category.item_category.map((item) => item.price);
          let resultSolveFee = solveFeeNew(saveMultiple(sumFees, FEE_MULTIPLIER), itemQTYList, itemPriceList);
          let suggesQTYIndex = 0;
          let suggestPrice = resultSolveFee[2];
          let suggestQTY = resultSolveFee[4];

          category.item_category.forEach((cateItem) => {
            let newCateItem = { ...cateItem };
            let itemContract = category.contract_items && category.contract_items.find((contractItem) => contractItem.item_id === newCateItem.id);

            if (newCateItem.qty === suggestQTY[suggesQTYIndex]) {
              expRatio.push({
                categoty_item_id: cateItem.id,
                expense_ratio: suggestPrice[suggesQTYIndex],
                expense_ratio_arr: null,
              });

              if (itemContract) newCateItem.price = parseFloat(itemContract.price) + parseFloat(suggestPrice[suggesQTYIndex] / 100);
              itemShowList.push({ ...newCateItem });
              suggesQTYIndex = suggesQTYIndex + 1;
            } else {
              let reminingQTY = newCateItem.qty;

              while (reminingQTY > 0) {
                let indexExpRatio = expRatio.findIndex((exp) => exp.categoty_item_id === cateItem.id);

                if (indexExpRatio > -1) {
                  for (let i = 0; i < suggestQTY[suggesQTYIndex]; i++) {
                    expRatio[indexExpRatio].expense_ratio_arr.push(suggestPrice[suggesQTYIndex]);
                  }
                } else {
                  let expArr = [];

                  for (let i = 0; i < suggestQTY[suggesQTYIndex]; i++) {
                    expArr.push(suggestPrice[suggesQTYIndex]);
                  }

                  expRatio.push({
                    categoty_item_id: cateItem.id,
                    expense_ratio: 0,
                    expense_ratio_arr: expArr,
                  });
                }

                newCateItem.qty = suggestQTY[suggesQTYIndex];
                if (itemContract) newCateItem.price = parseFloat(itemContract.price) + parseFloat(suggestPrice[suggesQTYIndex] / 100);
                itemShowList.push({ ...newCateItem });
                reminingQTY = reminingQTY - suggestQTY[suggesQTYIndex];
                suggesQTYIndex = suggesQTYIndex + 1;
              }
            }
          });

          onChangeExpRatio(expRatio);

          return itemShowList.map((item, itemIndex) => {
            const contract_item = category.contract_items && category.contract_items.find((contract_item) => contract_item.item_id === item.id);

            let resultBomSolveFee = [];
            let feePrice = [];
            let bomQTYList = [];
            let bomPriceList = [];

            if (item.is_mbom === false) {
              item_index = item_index + 1;
            } else {
              bomQTYList = item.bom_items_list.map(() => 1);
              bomPriceList = item.bom_items_list.map((item) => item.price);
              resultBomSolveFee = solveFeeNew(suggestPrice[itemIndex], bomQTYList, bomPriceList);
              feePrice = resultBomSolveFee[2];
            }

            console.log("suggestPrice[itemIndex]");

            return item.is_mbom ? (
              item.bom_items_list.map((bom, bom_index) => {
                item_index = item_index + 1;
                let bomItemPrice = 0;
                if (bom.price) {
                  bomItemPrice = new Intl.NumberFormat().format(parseFloat(bom.price) + parseFloat(feePrice[bom_index] / 100));
                }

                return (
                  <React.Fragment key={item_index + bom_index}>
                    <div className={`px-3 py-2 ${item_index % 2 !== 0 ? "" : "bg-aliceblue"} ${item_index === 0 ? "border-first" : ""} ${item_index + 1 === categories.length ? "border-last" : ""}`}>
                      <div className="row">
                        <div className="col-12 d-flex flex-row align-items-center">
                          <div className="col-custom-10 col-custom-sm-20">{item_index + 1}</div>
                          <div className="col-custom-30 col-custom-sm-50">{`${bom.name ? `(${item.name}) ${bom.name}` : "-"}`}</div>
                          <div className="col-custom-20 text-right flex-custom-sm-none">{`${`${item.qty ? item.qty.toLocaleString() : 1} ชุด`}`}</div>
                          <div className="col-custom-20 text-right flex-custom-sm-none">{`${bom.price ? bomItemPrice : "-"}`}</div>
                          <div className="col-custom-20 col-custom-sm-30 text-right justify-content-end">
                            {`${
                              bom.price
                                ? (removeComma(bomItemPrice) * (item.qty ? parseInt(item.qty) : 1)).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })
                                : "-"
                            }`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })
            ) : (
              <React.Fragment key={item_index}>
                <div className={`px-3 py-2 ${item_index % 2 !== 0 ? "" : "bg-aliceblue"} ${item_index === 0 ? "border-first" : ""} ${item_index + 1 === categories.length ? "border-last" : ""}`}>
                  <div className="row">
                    <div className="col-12 d-flex flex-row align-items-center">
                      <div className="col-custom-10 col-custom-sm-20">{item_index + 1}</div>
                      <div className="col-custom-30 col-custom-sm-50">{`${item.name ? item.name : "-"}`}</div>
                      <div className="col-custom-20 text-right flex-custom-sm-none">{`${item.unit ? `${item.qty ? item.qty.toLocaleString() : 1} ${item.unit}` : "-"}`}</div>
                      <div className="col-custom-20 text-right flex-custom-sm-none">
                        {`${
                          contract_item
                            ? contract_item.price
                              ? (contract_item.price + suggestPrice[itemIndex] / 100).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                })
                              : 0
                            : item.contract_price
                            ? contract_item.price
                              ? (item.contract_price + suggestPrice[itemIndex] / 100).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                })
                              : 0
                            : "-"
                        }`}
                      </div>
                      <div className="col-custom-20 col-custom-sm-30 text-right justify-content-end">{`${
                        contract_item
                          ? ((contract_item.price + suggestPrice[itemIndex] / 100) * (item.qty || 1)).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : item.contract_price
                          ? ((item.contract_price + suggestPrice[itemIndex] / 100) * (item.qty || 1)).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : "-"
                      }`}</div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          });
        })}
      </div>
    ) : (
      <div className="empty-content">
        <img src={EmptyIcon} width={50} height={50} />
        <span>กรุณาเลือกสินค้า</span>
      </div>
    );
  }

  function DisplayResultTable() {
    return (
      <React.Fragment>
        <div className="card-box px-3 py-2 mt-3 my-2 text-bold">
          <div className="row">
            <div className="col-12 d-flex flex-row align-items-center">
              <div className="col-custom-10 col-custom-sm-20">ลำดับ</div>
              <div className="col-custom-30 col-custom-sm-50">สินค้า</div>
              <div className="col-custom-20 text-right flex-custom-sm-none">จำนวน</div>
              <div className="col-custom-20 text-right flex-custom-sm-none">ราคา</div>
              <div className="col-custom-20 col-custom-sm-30 text-right justify-content-end">ราคารวม</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  function DisplaySummary(title, price, is_summary = false) {
    return (
      <div className={`${is_summary ? "summary-content-result" : "summary-content"}`}>
        <text className="text-right">{title}</text>
        <text className="text-right">{price}</text>
      </div>
    );
  }

  function onCheckMultiSelect(item, type) {
    const selected = multiSelect;

    if (type === "add") {
      let isHaved = selected.findIndex((select) => select.id === item.id);

      if (isHaved === -1) {
        selected.push(item);
      }
    } else if (type === "remove") {
      let removeIndex = selected.findIndex((select) => select.id === item.id);
      if (removeIndex !== -1) {
        selected.splice(removeIndex, 1);
      }
    }

    setMultiSelect(selected);
  }

  function getSumPrice(data, isDiscount = true) {
    let sum = 0;
    let sum_fee = 0;
    let category_qty = 0;
    let discount = data.discount.qty * data.discount.price;

    if (data.item_category_fees) {
      data.item_category.forEach((item) => {
        let loop_sum = 0;
        const contract_item = data.contract_items && data.contract_items.find((contract) => contract.item_id === item.id);
        const itemQTY = item.qty;
        category_qty = category_qty + itemQTY;
        if (contract_item) {
          sum = sum + contract_item.price * itemQTY || 0;
        } else if (item.contract_price) {
          sum = sum + item.contract_price * itemQTY || 0;
        }

        if (item.contract_item_fees) {
          item.contract_item_fees.forEach((fee) => {
            let feeQTY = fee.qty || 0;
            if (fee.method_type === 6) {
              if (itemQTY >= fee.from_value && itemQTY <= fee.to_value) {
                loop_sum = fee.price;
                sum = sum + loop_sum;
              } else if (!fee.to_value && itemQTY >= fee.from_value) {
                loop_sum = fee.price;
                sum = sum + loop_sum;
              }
              // console.log('loop_sum', loop_sum)
              //
            } else if (fee.method_type === 3) {
              if (itemQTY >= fee.from_value) {
                loop_sum = feeQTY * fee.price;
                sum = sum + loop_sum;
              }
            } else if (fee.method_type === 5) {
              // let itemQTY = item.qty;
              let multiply = Math.ceil(itemQTY / fee.package_qty);
              let priceQty = fee.price * feeQTY;

              sum = sum + priceQty * multiply;
            } else {
              let priceWithQty = fee.price * feeQTY;
              sum = sum + priceWithQty;
            }
          });
        }
      });

      data.item_category_fees.forEach((fee) => {
        let feeQTY = fee.qty || 0;

        if (feeQTY > 0) {
          if (fee.method_type === 6) {
            if (category_qty >= fee.from_value && category_qty <= fee.to_value) {
              sum_fee = fee.price;
            } else if (!fee.to_value && category_qty >= fee.from_value) {
              sum_fee = fee.price;
            }
          } else if (fee.method_type === 3) {
            if (category_qty >= fee.from_value) {
              sum = sum + fee.price * category_qty;
            }
          } else if (fee.method_type === 5) {
            // let itemQTY = item.qty;
            let multiply = Math.ceil(category_qty / fee.package_qty);
            let priceQty = fee.price * feeQTY;

            sum = sum + priceQty * multiply;
          } else {
            let priceWithQty = fee.price * feeQTY;
            sum = sum + priceWithQty;
          }
        }
      });

      sum = sum + sum_fee;

      data.add_expense.forEach((addExpense) => {
        sum = sum + addExpense.qty * addExpense.price;
      });
    }

    let result = isDiscount ? sum - discount : sum;

    return result;
  }

  function getSumFees(data) {
    let fee = 0;
    let result = 0;
    let categoty_sum = 0;
    let categotyQTY = 0;
    let discount = data.discount.qty * data.discount.price;

    data.item_category.forEach((item) => {
      let loop_sum = 0;
      let itemQTY = item.qty || 0;
      categotyQTY = categotyQTY + itemQTY;

      if (item.contract_item_fees) {
        item.contract_item_fees.forEach((contractFee) => {
          let contractFeeQTY = contractFee.qty || 0;

          if (contractFee.method_type === 6) {
            if (itemQTY >= contractFee.from_value && itemQTY <= contractFee.to_value) {
              loop_sum = contractFee.price;
            } else if (contractFee.to_value === null && itemQTY >= contractFee.from_value) {
              loop_sum = contractFee.price;
            }
          } else if (contractFee.method_type === 3) {
            if (itemQTY >= contractFee.from_value) {
              if (!contractFee.to_value) {
                fee = fee + contractFeeQTY * contractFee.price;
              } else {
                loop_sum = contractFeeQTY * contractFee.price;
              }
            }
          } else if (contractFee.method_type === 5) {
            let itemQTY = item.qty;
            let multiply = Math.ceil(itemQTY / contractFee.package_qty);
            let priceQty = contractFee.price * contractFeeQTY;

            fee = fee + priceQty * multiply;
          } else {
            let priceWithQty = contractFee.price * contractFeeQTY;
            fee = fee + priceWithQty;
          }
        });

        fee = fee + loop_sum;
      }
    });

    data.item_category_fees.forEach((categoryFee) => {
      let categoryFeeQTY = categoryFee.qty || 0;

      if (categoryFeeQTY > 0) {
        if (categoryFee.method_type === 6) {
          if (categotyQTY >= categoryFee.from_value && categotyQTY <= categoryFee.to_value) {
            categoty_sum = categoryFee.price;
          } else if (categoryFee.to_value === null && categotyQTY >= categoryFee.from_value) {
            categoty_sum = categoryFee.price;
          }
        } else if (categoryFee.method_type === 3) {
          if (categotyQTY >= categoryFee.from_value) {
            fee = fee + categotyQTY * categoryFee.price;
          }
        } else if (categoryFee.method_type === 5) {
          let multiply = Math.ceil(categotyQTY / categoryFee.package_qty);
          let priceQty = categoryFee.price * categoryFeeQTY;
          fee = fee + priceQty * multiply;
        } else {
          let priceWithQty = categoryFee.price * categoryFeeQTY;
          fee = fee + priceWithQty;
        }
      }
    });

    fee = fee + categoty_sum;

    data.add_expense.forEach((addExpense) => {
      fee = fee + addExpense.qty * addExpense.price;
    });

    result = fee - discount;

    return result;
  }

  function generateCategoryFeeElemet(element, item_categoty) {
    let new_element = [];
    let single_element = null;
    let sumQTY = 0;

    item_categoty.forEach((categoty) => {
      sumQTY = sumQTY + categoty.qty || 1;
    });

    if (single_element !== null) new_element.push(single_element);

    if (element) {
      element.forEach((item) => {
        if (item.method_type === 6) {
          if (sumQTY >= item.from_value && sumQTY <= item.to_value) {
            single_element = item;
          } else if (item.to_value === null && sumQTY >= item.from_value) {
            single_element = item;
          }
        } else if (item.method_type === 3) {
          if (sumQTY >= item.from_value) {
            single_element = item;
          }
        } else {
          new_element.push(item);
        }
      });

      if (single_element !== null) new_element.push(single_element);
    }

    return new_element;
  }

  function generateElemet(element, categoty) {
    let new_element = [];
    let single_element = null;
    let cateQTY = categoty.qty || 1;
    if (element) {
      element.forEach((item) => {
        if (item.method_type === 6) {
          if (cateQTY >= item.from_value && cateQTY <= item.to_value) {
            single_element = item;
          } else if (item.to_value === null && cateQTY >= item.from_value) {
            single_element = item;
          }
        } else if (item.method_type === 3) {
          if (cateQTY >= item.from_value) {
            if (item.to_value === null) {
              new_element.push(item);
            } else {
              single_element = item;
            }
          }
        } else {
          new_element.push(item);
        }
      });

      if (single_element !== null) new_element.push(single_element);
    }

    return new_element;
  }

  function checkDisableStatus(state) {
    if (state != "cancelled") {
      let findDisabledStatus = disable_input_status.find((status) => status === state);
      return findDisabledStatus;
    } else {
      return true;
    }
  }

  function reCalExpense(fee, index, category, value) {
    let isMultiply = getMultiplyType(fee, category.qty || 1);
    let contractItem = value.contract_items && value.contract_items.find((item) => item.item_id === category.id);

    let contractItemFee = contractItem && contractItem.contract_item_fees && contractItem.contract_item_fees.find((itemFee) => itemFee.item_fee_id === fee.item_fee_id);

    return (
      contractItemFee &&
      contractItemFee.qty && (
        <div key={index} className="col-12">
          <table class="table table-striped table-mobile-responsive table-mobile-sided d-md-none">
            <tr>
              <td data-content="บริการ">{fee.name}</td>
              <td data-content="จำนวน" className="p-1">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} value={fee.qty} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => onChangeItemFeeQTY(e, category, fee, value)} />
                <label htmlFor="">{fee.unit ? (isMultiply > 1 ? `${fee.unit}X${isMultiply}` : fee.unit) : "-"}</label>
              </td>
              <td data-content="ราคา">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} value={fee.price} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => onChangeItemFeePrice(e, category, fee, value)} />
              </td>
              <td data-content="ราคารวม">
                <div className="col-break-word text-right pr-2">
                  {fee.price
                    ? isMultiply
                      ? (fee.price * fee.qty * isMultiply).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : (fee.price * fee.qty).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                    : "0.00"}
                </div>
                {order_data && !checkDisableStatus(order_data.state) && (
                  <div className="delete-expensive" style={{ minWidth: "30px" }} onClick={() => onClickDeleteItemFee(category, fee, value)}>
                    {"\u2715"}
                  </div>
                )}
              </td>
            </tr>
          </table>
          <div className={`row mt-2 border d-none d-md-flex`}>
            <div className="table-item-bg first col-1 justify-content-center">{/* {index + 1 + value.item_category.length + value.item_expense.length + (contract_items_index > 0 ? value.contract_items[contract_items_index - 1].contract_item_fees.length : 0)} */}</div>
            <div className="table-item-bg col-5 px-0">{fee.name}</div>
            <div className="table-item-bg col-2 pl-0 justify-content-end">
              <div className="row align-items-center justify-content-end">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} value={fee.qty} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => onChangeItemFeeQTY(e, category, fee, value)} />
                <div className="col-5 text-left pl-0">{fee.unit ? (isMultiply > 1 ? `${fee.unit}X${isMultiply}` : fee.unit) : "-"}</div>
              </div>
            </div>
            <div className="table-item-bg col-2 pl-0 justify-content-end d-relative">
              <div className="col-break-word text-right">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={0} value={fee.price || 0} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => onChangeItemFeePrice(e, category, fee, value)} />
              </div>
            </div>
            <div className="table-item-bg col-2 pl-0 justify-content-end">
              <div className="col-break-word text-right pr-2">
                {fee.price
                  ? isMultiply
                    ? (fee.price * fee.qty * isMultiply).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : (fee.price * fee.qty).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                  : "0.00"}
              </div>
              {order_data && !checkDisableStatus(order_data.state) && (
                <div className="delete-expensive" style={{ minWidth: "30px" }} onClick={() => onClickDeleteItemFee(category, fee, value)}>
                  {"\u2715"}
                </div>
              )}
            </div>
          </div>
          <a class="d-none">
            <div className={`row mt-2 border d-md-none`}>
              <div className="table-item-bg col-12 pt-1 col-md-4 pr-3">{fee.name}</div>
              <div className="table-item-bg col col-md-1 text-center d-md-none">
                จำนวน {fee.qty} {fee.unit}
              </div>
              <div className="table-item-bg col-12 col-md d-flex justify-content-end bg-ghostwhite d-md-none">
                <h4 className="sign-baht color-darkgrayblue my-0">
                  {fee.price && fee.qty
                    ? (fee.price * fee.qty).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : "0.00"}
                </h4>
              </div>
            </div>
          </a>
        </div>
      )
    );
  }

  function getMultiplyType(item, item_qty) {
    let value = null;
    if (item.method_type === 5) {
      let muliple = Math.ceil(item_qty / item.package_qty);

      value = muliple;
    }

    return value;
  }

  function reCalCategoryFee(expense, index, value) {
    let sumQTY = 0;
    value.item_category.forEach((itemCate) => {
      sumQTY = sumQTY + itemCate.qty;
    });
    let isMultiply = getMultiplyType(expense, sumQTY);

    return (
      expense &&
      expense.qty && (
        <div key={index} className="col-12">
          <table class="table table-striped table-mobile-responsive table-mobile-sided d-md-none">
            <tr>
              <td data-content="บริการ">{expense.name}</td>
              <td data-content="จำนวน">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} value={expense.qty} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => onChangeCategotyFeeQTY(e, expense, value)} />
                <label htmlFor="">{expense.unit ? (isMultiply > 1 ? `${expense.unit}X${isMultiply}` : expense.unit) : "-"}</label>
              </td>
              <td data-content="ราคา">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} value={expense.price} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => onChangeCategotyFeePrice(e, expense, value)} />
              </td>
              <td data-content="รวม">
                <div className="col-break-word text-right pr-2">
                  {isMultiply > 1
                    ? (expense.qty ? expense.price * expense.qty * isMultiply : expense.price * 1 * isMultiply).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : (expense.qty ? expense.price * expense.qty : expense.price * 1).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                </div>
                {order_data && !checkDisableStatus(order_data.state) && (
                  <div className="delete-expensive" style={{ minWidth: "30px" }} onClick={() => onClickDeleteCategotyFee(expense, value)}>
                    {"\u2715"}
                  </div>
                )}
              </td>
            </tr>
          </table>
          {/* md up */}
          <div className={`row mt-2 border d-none d-md-flex`}>
            <div className="table-item-bg first col-1 justify-content-center"> </div>
            <div className="table-item-bg col-5 px-0">{expense.name}</div>
            <div className="table-item-bg col-2 pl-0 justify-content-end">
              <div className="row align-items-center justify-content-end">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} value={expense.qty} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => onChangeCategotyFeeQTY(e, expense, value)} />
                <div className="col-5 text-left pl-0">{expense.unit ? (isMultiply > 1 ? `${expense.unit}X${isMultiply}` : expense.unit) : "-"}</div>
              </div>
            </div>
            <div className="table-item-bg col-2 pl-0 justify-content-end d-relative">
              <div className="col-break-word text-right">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={0} value={expense.price || 0} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => onChangeCategotyFeePrice(e, expense, value)} />
              </div>
            </div>
            <div className="table-item-bg col-2 pl-0 justify-content-end">
              <div className="col-break-word text-right pr-2">
                {isMultiply > 1
                  ? (expense.qty ? expense.price * expense.qty * isMultiply : expense.price * 1 * isMultiply).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  : (expense.qty ? expense.price * expense.qty : expense.price * 1).toLocaleString(undefined, { minimumFractionDigits: 2 })}
              </div>
              {order_data && !checkDisableStatus(order_data.state) && (
                <div className="delete-expensive" style={{ minWidth: "30px" }} onClick={() => onClickDeleteCategotyFee(expense, value)}>
                  {"\u2715"}
                </div>
              )}
            </div>
          </div>
          {/* md down */}
          <a class="d-none">
            <div className={`row mt-2 border d-md-none`}>
              <div className="table-item-bg col-12 pt-1 col-md-4 pr-3">{expense.name}</div>
              <div className="table-item-bg col col-md-1 text-center d-md-none">
                จำนวน {expense.qty} {expense.unit}
              </div>
              <div className="table-item-bg col-12 col-md d-flex justify-content-end bg-ghostwhite d-md-none">
                <h4 className="sign-baht color-darkgrayblue my-0">
                  {isMultiply > 1
                    ? (expense.price * expense.qty * isMultiply).toLocaleString(undefined, { minimumFractionDigits: 2 })
                    : (expense.price * expense.qty).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                </h4>
              </div>
            </div>
          </a>
        </div>
      )
    );
  }

  function DisplayExpenseAdded(data, index, value) {
    return (
      data.qty > 0 && (
        <div key={index} className="col-12">
          <table class="table table-striped table-mobile-responsive table-mobile-sided d-md-none">
            <tr>
              <td data-content="บริการ">{data.name}</td>
              <td data-content="จำนวน">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} value={data.qty || 1} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => onChangeQTYAddExpense(e, data, value)} />
                <label htmlFor="">{data.unit ? data.unit : "-"}</label>
              </td>
              <td data-content="ราคา">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={0} value={data.price} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => onChangePriceAddExpense(e, data, value)} />
              </td>
              <td data-content="ราคารวม">
                <div className="col-break-word text-right pr-2">{(data.qty ? data.price * data.qty : data.price * 1).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
                {order_data && !checkDisableStatus(order_data.state) && (
                  <div className="delete-expensive" style={{ minWidth: "30px" }} onClick={() => onDeleteAddExpense(data, value)}>
                    {"\u2715"}
                  </div>
                )}
              </td>
            </tr>
          </table>
          {/* md up */}
          <div className={`row mt-2 border d-none d-md-flex`}>
            <div className="table-item-bg first col-1 justify-content-center"></div>
            <div className="table-item-bg col-5 px-0">{data.name}</div>
            <div className="table-item-bg col-2 pl-0 justify-content-end">
              <div className="row align-items-center justify-content-end">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} value={data.qty || 1} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => onChangeQTYAddExpense(e, data, value)} />
                <div className="col-5 text-left pl-0">{data.unit ? data.unit : "-"}</div>
              </div>
            </div>
            <div className="table-item-bg col-2 pl-0 justify-content-end d-relative">
              <div className="col-break-word text-right">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={0} value={data.price || 0} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => onChangePriceAddExpense(e, data, value)} />
              </div>
            </div>
            <div className="table-item-bg col-2 pl-0 justify-content-end">
              <div className="col-break-word text-right pr-2">{(data.qty ? data.price * data.qty : data.price * 1).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
              {order_data && !checkDisableStatus(order_data.state) && (
                <div className="delete-expensive" style={{ minWidth: "30px" }} onClick={() => onDeleteAddExpense(data, value)}>
                  {"\u2715"}
                </div>
              )}
            </div>
          </div>
          {/* md down */}
          <a class="d-none">
            <div className={`row mt-2 border d-none`}>
              <div className="table-item-bg col-12 pt-1 col-md-4 pr-3">{data.name}</div>
              <div className="table-item-bg col col-md-1 text-center d-md-none">
                จำนวน {data.qty} {data.unit}
              </div>
              <div className="table-item-bg col-12 col-md d-flex justify-content-end bg-ghostwhite d-md-none">
                <h4 className="sign-baht color-darkgrayblue my-0">
                  {(data.price * data.qty).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </h4>
              </div>
            </div>
          </a>
        </div>
      )
    );
  }

  function DisplayItemDiscount(contractItems, itemCate) {
    let discountList = [];

    contractItems.contract_item_discounts.forEach((item) => {
      let findDuplicate = discountList.find((discount) => discount.discount_item_id === item.discount_item_id);
      if (!findDuplicate) {
        discountList.push(item);
      }
    });

    return discountList.map((item) => {
      item["qty"] = 1;
      const price = item.price;
      const qty = item.qty;
      let itemDetail = itemCate.item_category.find((c) => c.id === item.discount_item_id);

      if (!itemDetail || !item.discount_item_id) {
        return;
      }

      return (
        <div className="col-12">
          <table class="table table-striped table-mobile-responsive table-mobile-sided  d-md-none">
            <tr>
              <td data-content="ส่วนลด" style={{ color: "red" }}>
                ส่วนลดรายหมวดหมู่
              </td>
              <td data-content="จำนวน">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} value={qty} className="qty-input mr-2" controls={false} stringMode={false} style={{ marginLeft: 10 }} />
              </td>
              <td data-content="ราคา">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} value={price} className="qty-discount-input" controls={false} stringMode={false} />
              </td>
              <td data-content="ราคารวม">
                <div className="col-break-word text-right pr-2" style={{ color: "red" }}>
                  {qty && price > 1 && "-"}
                  {(qty && price > 1 ? qty * price : 0).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                </div>
              </td>
            </tr>
          </table>
          <div className={`row mt-2 border d-none d-md-flex`}>
            <div className="table-item-bg first col-1 justify-content-center"></div>
            <div className="table-item-bg col-5 px-0" style={{ color: "red" }}>
              ส่วนลด {itemDetail.name}
            </div>
            <div className="table-item-bg col-2 pl-0 justify-content-end">
              <div className="row align-items-center justify-content-end">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} value={qty} className="qty-input mr-2" controls={false} stringMode={false} style={{ marginLeft: 10 }} />
                <div className="col-5 text-left pl-0"> </div>
              </div>
            </div>
            <div className="table-item-bg col-2 p-0 justify-content-end d-relative">
              <div>
                <div className="col-break-word text-right">
                  <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} value={price} className="qty-discount-input" controls={false} stringMode={false} />
                </div>
              </div>
            </div>
            <div className="table-item-bg col-2 pl-0 justify-content-end">
              <div className="col-break-word text-right pr-2" style={{ color: "red" }}>
                {qty && price > 1 && "-"}
                {(qty && price > 1 ? qty * price : 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  function DisplayDiscount(qty, price, value) {
    return (
      <div className="col-12">
        <table class="table table-striped table-mobile-responsive table-mobile-sided  d-md-none">
          <tr>
            <td data-content="ส่วนลด" style={{ color: "red" }}>
              ส่วนลดรายหมวดหมู่
            </td>
            <td data-content="จำนวน">
              <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} value={qty} className="qty-input mr-2" controls={false} stringMode={false} style={{ marginLeft: 10 }} onChange={(e) => onChangeDiscount(e, "qty", value)} />
            </td>
            <td data-content="ราคา">
              <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} value={price} className="qty-discount-input" controls={false} stringMode={false} onChange={(e) => onChangeDiscount(e, "price", value)} />
            </td>
            <td data-content="ราคารวม">
              <div className="col-break-word text-right pr-2" style={{ color: "red" }}>
                {qty && price > 1 && "-"}
                {(qty && price > 1 ? qty * price : 0).toLocaleString(undefined, { minimumFractionDigits: 2 })}
              </div>
            </td>
          </tr>
        </table>
        <div className={`row mt-2 border d-none d-md-flex`}>
          <div className="table-item-bg first col-1 justify-content-center"></div>
          <div className="table-item-bg col-5 px-0" style={{ color: "red" }}>
            ส่วนลดรายหมวดหมู่
          </div>
          <div className="table-item-bg col-2 pl-0 justify-content-end">
            <div className="row align-items-center justify-content-end">
              <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} value={qty} className="qty-input mr-2" controls={false} stringMode={false} style={{ marginLeft: 10 }} onChange={(e) => onChangeDiscount(e, "qty", value)} />
              <div className="col-5 text-left pl-0"> </div>
            </div>
          </div>
          <div className="table-item-bg col-2 p-0 justify-content-end d-relative">
            <div>
              <div className="col-break-word text-right">
                <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} value={price} className="qty-discount-input" controls={false} stringMode={false} onChange={(e) => onChangeDiscount(e, "price", value)} />
              </div>
            </div>
          </div>
          <div className="table-item-bg col-2 pl-0 justify-content-end">
            <div className="col-break-word text-right pr-2" style={{ color: "red" }}>
              {qty && price > 1 && "-"}
              {(qty && price > 1 ? qty * price : 0).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function DisplayItemsCustomize(category) {
    return category.length > 0 ? (
      category.map((value, cate_index) => {
        const isContractExist = value.contract_list.find((contract) => contract.id === value.contract_id);

        if (!isContractExist && isChangeApproveDate) {
          setIsChangeApprovedDate(false);
          onChangeContract(
            value.contract_list[0] ? value.contract_list[0].id : null,
            value.id,
            value.item_category.map((item) => item.id)
          );
        }
        return (
          <section key={cate_index}>
            <div className="col-12">
              <div className="row bg-lightred align-items-center">
                <div className="item-custom-header category-title first font-weight-bold col-12 col-md-3 pr-0">
                  <div className={`align-items-center pr-2`}>
                    <i className={`icon-adjust cursor-pointe mt-2`} onClick={() => onHandleFeeAdjustModal(value.id, value.sys_name)} />
                  </div>
                  {value.sys_name}
                </div>
                <div className="col-12 col-md-5 justify-content-end d-block">
                  <div className="select-custom-content">
                    <Select
                      disabled={order_data && checkDisableStatus(order_data.state)}
                      wrapperclass="ant-select-selection-item"
                      onChange={(id) =>
                        onChangeContract(
                          id,
                          value.id,
                          value.item_category.map((item) => item.id)
                        )
                      }
                      placeholder={value.contract_list.length === 0 ? "ไม่พบสัญญา" : "กรุณาเลือกสัญญา..."}
                      value={value.contract_list.length > 0 ? (value.contract_id ? (isContractExist ? value.contract_id : value.contract_list[0].id) : value.contract_list[0].id) : null}
                      options={value.contract_list.map((item) => ({
                        label: `${item.zone.name} - ${item.name} (${item.code})`,
                        value: item.id,
                      }))}
                    />
                  </div>
                </div>
                <div className="item-custom-header category-title col-12 col-md-2 pl-0 pr-3 text-bold text-right">
                  <div className="col-break-word sign-baht">{`ค่าบริการ ${getSumFees(value).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}`}</div>
                </div>
                <div className="item-custom-header category-title col-12 col-md-2 pl-0 pr-3 text-bold text-right">
                  <div className="col-break-word sign-baht">{`รวม ${getSumPrice(value).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}`}</div>
                </div>
              </div>
            </div>

            {value.item_category.map((item, index) => {
              const contract_item = value.contract_items && value.contract_items.find((contract_item) => contract_item.item_id === item.id);
              return (
                <div key={index} className="col-12">
                  <table class="table table-striped table-mobile-responsive table-mobile-sided d-md-none">
                    <tr>
                      <td data-content="ลำดับ">{index + 1}</td>
                      <td data-content="สินค้า">{item.name}</td>
                      <td data-content="จำนวน">
                        <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} defaultValue={1} value={item.qty || 1} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => handelChangeItemQTY(e, value, item)} />
                        <label htmlFor="">{item.unit ? item.unit : item.is_mbom ? "ชุด" : "-"}</label>
                      </td>
                      <td data-content="ราคา">
                        {contract_item ? (
                          <div>
                            <div className="col-break-word text-right">
                              <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} value={item.contract_price} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => handelChangeItemPrice(e, value, item)} />
                            </div>
                            {!contract_item.price ||
                              (parseInt(contract_item.price) === 0 && (
                                <Tooltip placement="bottom" title={`ราคาสินค้าชิ้นนี้ในสัญญาเป็น 0 บาท`}>
                                  <div className="alert-not-match">!</div>
                                </Tooltip>
                              ))}
                          </div>
                        ) : (
                          <div className="expense-total">
                            <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={0} defaultValue={0} className="qty-input mr-2" controls={false} stringMode={false} />
                            <Tooltip placement="bottom" title={`ไม่มีสินค้าชิ้นนี้ในสัญญา`}>
                              <div className="alert-not-match">!</div>
                            </Tooltip>
                          </div>
                        )}
                      </td>
                      <td data-content="รวม">
                        <div className="col-break-word text-right pr-2">
                          {contract_item && contract_item.price && value.qty
                            ? (contract_item.price * (item.qty || 1)).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })
                            : item.contract_price
                            ? (item.contract_price * (item.qty || 1)).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })
                            : "0.00"}
                        </div>
                        {order_data && !checkDisableStatus(order_data.state) && (
                          <div className="delete-expensive" style={{ minWidth: "30px" }} onClick={() => onClickDeleteItem(contract_item, value)}>
                            {"\u2715"}
                          </div>
                        )}
                      </td>
                    </tr>
                  </table>
                  <div className={`row mt-2 border d-none d-md-flex`}>
                    <div className="table-item-bg first col-1 justify-content-center">{index + 1}</div>
                    <div className="table-item-bg col-5 px-0">{item.name}</div>
                    <div className="table-item-bg col-2 pl-0 justify-content-end">
                      <div className="row align-items-center justify-content-end">
                        <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} defaultValue={1} value={item.qty} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => handelChangeItemQTY(e, value, item)} />
                        <div className="col-5 text-left pl-0">{item.unit ? item.unit : item.is_mbom ? "ชุด" : "-"}</div>
                      </div>
                    </div>
                    <div className="table-item-bg col-2 pl-0 justify-content-end d-relative">
                      {contract_item ? (
                        <div>
                          <div className="col-break-word text-right">
                            <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={0} value={contract_item.price || 0} className="qty-input mr-2" controls={false} stringMode={false} onChange={(e) => handelChangeItemPrice(e, value, item)} />
                          </div>
                          {!contract_item.price ||
                            (parseInt(contract_item.price) === 0 && (
                              <Tooltip placement="bottom" title={`ราคาสินค้าชิ้นนี้ในสัญญาเป็น 0 บาท`}>
                                <div className="alert-not-match">!</div>
                              </Tooltip>
                            ))}
                        </div>
                      ) : (
                        <div className="expense-total">
                          <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={0} defaultValue={item.contract_price || 0} className="qty-input mr-2" controls={false} stringMode={false} />
                          <Tooltip placement="bottom" title={`ไม่มีสินค้าชิ้นนี้ในสัญญา`}>
                            <div className="alert-not-match">!</div>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    <div className="table-item-bg col-2 pl-0 justify-content-end">
                      <div className="col-break-word text-right pr-2">
                        {contract_item && contract_item.price && value.qty
                          ? (contract_item.price * (item.qty || 1)).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : "0.00"}
                      </div>
                      {order_data && !checkDisableStatus(order_data.state) && (
                        <div className="delete-expensive" style={{ minWidth: "30px" }} onClick={() => onClickDeleteItem(contract_item, value)}>
                          {"\u2715"}
                        </div>
                      )}
                    </div>
                  </div>
                  <a class="d-none">
                    <div className={`row mt-2 border d-md-none`}>
                      <div className="table-item-bg col-12 pt-1 col-md-4 pr-3">{item.name}</div>
                      <div className="table-item-bg col col-md-1 text-center d-md-none">
                        จำนวน {item.qty} {item.unit}
                      </div>
                      <div className="table-item-bg col-12 col-md d-flex justify-content-end bg-ghostwhite d-md-none">
                        <h4 className="sign-baht color-darkgrayblue my-0">{contract_item && contract_item.price && item.qty ? (contract_item.price * item.qty).toLocaleString(undefined, { minimumFractionDigits: 2 }) : "0.00"}</h4>
                      </div>
                    </div>
                  </a>
                </div>
                /************************/
              );
            })}
            {generateCategoryFeeElemet(value.item_category_fees, value.item_category).map((expense, index) => {
              return reCalCategoryFee(expense, index, value);
            })}

            {value.item_category.map((categoty) => {
              let elemFee = generateElemet(categoty.contract_item_fees, categoty);
              return elemFee.map((fee, index) => {
                return reCalExpense(fee, index, categoty, value);
              });
            })}

            {value.add_expense.map((addedData, index) => {
              return DisplayExpenseAdded(addedData, index, value);
            })}

            {value.contract_items &&
              value.contract_items.map((contract_item) => {
                return DisplayItemDiscount(contract_item, value);
              })}
            {DisplayDiscount(value.discount.qty, value.discount.price, value)}

            {order_data && checkDisableStatus(order_data.state) ? (
              ""
            ) : (
              <Button className="add-expense-btn mb-4" onClick={() => onClickAddExpenseModal(value)}>
                + เพิ่มค่าบริการ
              </Button>
            )}
          </section>
        );
      })
    ) : (
      <div className="empty-content">
        <img src={EmptyIcon} width={50} height={50} />
        <span>กรุณาเลือกสินค้า</span>
      </div>
    );
  }

  function onClickAddExpenseModal(value) {
    setExpenseModal(true);
    setCurrentSelectAddExpense(value);
  }

  function DisplaySummaryCustomize() {
    return (
      <div className="row table-header d-none d-md-flex text-bold px-3 mb-2">
        <div className="table-item-bg col-1 px-0 first justify-content-center">ลำดับ</div>
        <div className="table-item-bg col-5 px-0 ">สินค้า</div>
        <div className="table-item-bg col-2 pr-3 justify-content-end">จำนวน</div>
        <div className="table-item-bg col-2 pr-3 justify-content-end">ราคา</div>
        <div className="table-item-bg col-2 pr-3 justify-content-end">รวม</div>
      </div>
    );
  }

  async function onSubmitAdd() {
    setItemSelected(null);
    setQTYAdded(null);
    onOpenAddItemResponsive(false);
    await onSubmitAddItem();
  }

  function onChangeAddItem(value) {
    const itemID = parseInt(value.split("|")[0]);
    const selectd_item = inbound_data.item_dc.find((item) => item.id === itemID);

    setItemSelected(value);
    onSelectItemToAdd(selectd_item);
  }

  function onChangeQtyAddItem(e) {
    onChangeQtyItem(e);
    setQTYAdded(e);
  }

  function DisplayModalExpanAdd() {
    return (
      <Modal wrapClassName="expand-modal-container" open={expanAddModal} footer={false} closable={false} onCancel={() => onCancelMultiselect()} centered>
        <div className="header-expan-container">
          <div>เพิ่มรายการสินค้า</div>
          <div className="add-expense-item-content">
            <Button style={{ height: 40 }} type="primary" onClick={() => onSubmitMultiselect()}>
              เพิ่มสินค้า
            </Button>
            <Button
              style={{
                height: 40,
                backgroundColor: "gray",
                color: "white",
                border: "none",
              }}
              onClick={() => onCancelMultiselect()}
            >
              ยกเลิก
            </Button>
          </div>
        </div>
        <div className="item-modal-content">
          {categoryGroup &&
            categoryGroup.map((category, index) => {
              return <AddItemModal key={index} categoty_name={category.sys_name} items={category.item_dc} onCheckMultiSelect={onCheckMultiSelect} isReset={isResetMultipleSelect} setIsReset={setIsResetMultipleSelect} />;
            })}
        </div>
      </Modal>
    );
  }

  function DisplayAddItemContainerRsponsive() {
    return (
      <div className={"overlay-box"} style={{ marginTop: "-60px", zIndex: 999 }}>
        <div className="add-item-warper-res">
          <div className="add-item-content">
            <span>เพิ่มรายการสินค้า</span>
            {/* <div className="expand-modal-item">
            <i className="icon-fullscreen-white" onClick={() => onOpenMultiselect()} />
          </div> */}
          </div>
          <div className="item-detail-container">
            <Select disabled={order_data && checkDisableStatus(order_data.state)} placeholder="Select..." onChange={onChangeAddItem} value={itemSelected} showSearch>
              {categoryGroup &&
                categoryGroup.map((category) => {
                  return category.item_dc.map((item) => {
                    return (
                      <Option value={`${item.id}|${item.name}`}>
                        [{category.sys_name}] {item.name}
                      </Option>
                    );
                  });
                })}
            </Select>

            <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} controls={false} stringMode={false} placeholder="Quantity..." onChange={onChangeQtyAddItem} value={qtyAdded} className="qty-input-add-item" />
            <div style={{ display: "flex", gap: 5, width: "100%" }}>
              <Button
                style={{
                  width: "100%",
                  backgroundColor: "green",
                  border: "none",
                }}
                type="primary"
                className="add-item-button"
                onClick={() => onSubmitAdd()}
              >
                เพิ่ม
              </Button>
              <Button style={{ width: "100%" }} type="primary" className="add-item-button btn-cancel" onClick={() => onOpenAddItemResponsive(false)}>
                ยกเลิก
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function DisplayAddItemContainer() {
    return (
      <div className="add-item-warper">
        <div className="add-item-content">
          <span>เพิ่มรายการสินค้า</span>
          {order_data && checkDisableStatus(order_data.state) ? (
            ""
          ) : (
            <div className="expand-modal-item">
              <i className="icon-fullscreen-white" onClick={() => onOpenMultiselect()} />
            </div>
          )}
        </div>
        <div className="item-detail-container">
          <Select disabled={order_data && checkDisableStatus(order_data.state)} placeholder="Select..." onChange={onChangeAddItem} value={itemSelected} showSearch>
            {categoryGroup &&
              categoryGroup.map((category) => {
                return category.item_dc.map((item) => {
                  return (
                    <Option value={`${item.id}|${item.name}`}>
                      [{category.sys_name}] {item.name}
                    </Option>
                  );
                });
              })}
          </Select>

          <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} controls={false} stringMode={false} placeholder="Quantity..." onChange={onChangeQtyAddItem} value={qtyAdded} className="qty-input-add-item" />
          {order_data && checkDisableStatus(order_data.state) ? (
            ""
          ) : (
            <Button type="primary" className="add-item-button" onClick={() => onSubmitAdd()}>
              เพิ่ม
            </Button>
          )}
        </div>
      </div>
    );
  }

  function summaryAndDiscount(data) {
    let sumPrice = 0;
    let sumDiscount = 0;
    let sumVat = 0;
    let beforeDiscount = 0;
    let actualPrice = 0;
    data.forEach((listItem) => {
      sumDiscount = sumDiscount + listItem.discount.price * listItem.discount.qty;
      sumPrice = sumPrice + getSumPrice(listItem, false);
    });

    sumVat = ((sumPrice - sumDiscount) * 7) / 100;

    beforeDiscount = sumPrice - sumDiscount;

    actualPrice = beforeDiscount + sumVat;

    actualPrice = actualPrice.toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });

    beforeDiscount = beforeDiscount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });

    sumPrice = sumPrice.toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });

    sumDiscount = sumDiscount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });

    sumVat = sumVat.toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });

    return { sumPrice, sumDiscount, sumVat, beforeDiscount, actualPrice };
  }

  function displaySummary(md) {
    let summary = summaryAndDiscount(addList);

    if (md) {
      return (
        <section>
          <div className="card-box summary-container mb-3">
            {DisplaySummary("รวมเป็นเงิน", summary.sumPrice)}
            {DisplaySummary("ส่วนลด", summary.sumDiscount)}
            {DisplaySummary("หลังหักส่วนลด", summary.beforeDiscount)}
            {DisplaySummary("VAT 7%", summary.sumVat)}
            {DisplaySummary("รวมทั้งสิ้น", summary.actualPrice, true)}
          </div>
        </section>
      );
    } else {
      return (
        <section>
          <div className="add-item-container">
            {DisplayAddItemContainer(inbound_data.item_dc)}
            <div className="summary-container">
              {DisplaySummary("รวมเป็นเงิน", summary.sumPrice)}
              {DisplaySummary("ส่วนลด", summary.sumDiscount)}
              {DisplaySummary("หลังหักส่วนลด", summary.beforeDiscount)}
              {DisplaySummary("VAT 7%", summary.sumVat)}
              {DisplaySummary("รวมทั้งสิ้น", summary.actualPrice, true)}
            </div>
          </div>
        </section>
      );
    }
  }

  function DisplaySummaryContainer() {
    return (
      <div className="col">
        <div className="row align-items-center">
          <h5 className="pb-1 mb-0 mt-3">ปรับแต่งราคาขาซื้อ</h5>
        </div>
        <div className="row pt-1">
          <div className="col-12 col-lg-9 px-0">
            <section>
              {DisplaySummaryCustomize()}
              {DisplayItemsCustomize(addList)}
            </section>
            <div className="d-block d-lg-none">{displaySummary(true)}</div>
          </div>
          <div className="col-12 col-lg-3 px-0 d-none d-lg-block">{displaySummary()}</div>
        </div>
      </div>
    );
  }

  function DisplayRemark() {
    return (
      <div>
        <h5 className="pb-1 mb-0">ข้อมูลอื่น ๆ</h5>
        <div className="card-box p-3 mt-1">
          <div className="pb-2 text-bold">Note : </div>
          <TextArea disabled={order_data && checkDisableStatus(order_data.state)} value={noted} autoSize={{ minRows: 3, maxRows: 3 }} onChange={onChangeNoted} />
        </div>
      </div>
    );
  }

  function onChangeApprove(e) {
    onChangeApproveDate(e);
    setIsChangeApprovedDate(true);
  }

  function DisplayApproveHead() {
    return (
      <div className="row align-items-center px-3">
        <h5 className="pr-2 mb-0">สรุปราคาขาซื้อ (ต่อหน่วย)</h5>
        <div>
          <div className="row align-items-center px-3">
            <span className="pr-1 mt-1 text-bold">วันที่อนุมัติ (วันที่ดึงราคาสัญญา)</span>
            <span className="pr-2 red-mark">*</span>
            <span className="mt-1">
              <DatePicker disabled={order_data.state == "cancelled"} className="antd-datepicker-custom" placeholder="เลือกวันที่อนุมัติ" format={"DD/MM/YYYY"} value={moment(approveDate)} onChange={onChangeApprove} />
            </span>
          </div>
        </div>
      </div>
    );
  }

  function onChangeAddExpense(value, type) {
    if (type === "qty") {
      setAddExpenseQTY(value);
    } else if (type === "price") {
      setAddExpensePrice(value);
    }
  }

  function onFormSubmitAddExpense(values, category) {
    setAddExpensePrice(0);
    setAddExpenseQTY(0);
    setExpenseModal(false);
    onSubmitAddExpense(values, category);
    formRef.current.resetFields();
  }

  function onCancelAddExpense() {
    setExpenseModal(false);
    setAddExpensePrice(0);
    setAddExpenseQTY(0);
    formRef.current.resetFields();
  }

  function onCloseResetFee() {
    setIsModalReset(false);
    setResetName("");
    setResetID(0);
  }

  function onHandleFeeAdjustModal(id, name) {
    setResetName(name);
    setResetID(id);
    setIsModalReset(true);
  }

  function onConfirmResetFee() {
    resetFees(resetID);
    setIsModalReset(false);
    setResetName("");
    setResetID(0);
  }

  function DisplayResetFeesModal() {
    return (
      <Modal open={isModalReset} footer={false} centered closable={false} onCancel={() => onCloseResetFee()}>
        <div style={{ fontSize: 24, fontWeight: "bold" }}>ปรับค่าบริการเป็นค่าเริ่มต้น</div>
        <Divider style={{ margin: 10 }} />
        <div style={{ fontSize: 14 }}>ต้องการปรับค่าบริการของ {<span style={{ fontWeight: "bold", textDecoration: "underline" }}>{resetName}</span>} เป็นค่าเริ่มต้น ?</div>
        <div style={{ display: "flex", gap: 5, marginTop: 10 }}>
          <Button
            style={{
              width: "100%",
              backgroundColor: "#28a745",
              color: "white",
              border: "none",
            }}
            onClick={() => onConfirmResetFee()}
          >
            ตกลง
          </Button>
          <Button
            style={{
              width: "100%",
              backgroundColor: "gray",
              color: "white",
              border: "none",
            }}
            onClick={() => onCloseResetFee()}
          >
            ยกเลิก
          </Button>
        </div>
      </Modal>
    );
  }

  function DisplayAddExpenseModal() {
    return (
      <Modal open={expenseModal} onCancel={() => onCancelAddExpense()} closable={false} centered={true} footer={false}>
        <Form onFinish={(values) => onFormSubmitAddExpense(values, currentSelectAddExpense)} ref={formRef}>
          <text className="header-title-text">ค่าบริการ</text>
          <div className="input-content">
            <text className="expense-text">บริการ : </text>
            <Form.Item style={{ display: "flex", margin: 0 }} name="service_name" rules={[{ required: true, message: "กรุณากรอกบริการ !" }]}>
              <Input disabled={order_data && checkDisableStatus(order_data.state)} />
            </Form.Item>
          </div>
          <div className="input-content">
            <text className="expense-text">จำนวน : </text>
            <Form.Item style={{ display: "flex", margin: 0 }} name="service_qty" rules={[{ required: true, message: "กรุณากรอกจำนวน !" }]}>
              <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} className="qty-add-expense-input" controls={false} stringMode={false} onChange={(e) => onChangeAddExpense(e, "qty")} />
            </Form.Item>
          </div>
          <div className="input-content">
            <text className="expense-text">หน่วย : </text>
            <Form.Item style={{ display: "flex", margin: 0 }} name="service_unit" rules={[{ required: true, message: "กรุณากรอกหน่วย !" }]}>
              <Input disabled={order_data && checkDisableStatus(order_data.state)} />
            </Form.Item>
          </div>
          <div className="input-content">
            <text className="expense-text">ราคา : </text>
            <Form.Item style={{ display: "flex", margin: 0 }} name="service_price" rules={[{ required: true, message: "กรุณากรอกราคา !" }]}>
              <InputNumber disabled={order_data && checkDisableStatus(order_data.state)} min={1} className="qty-add-expense-input" controls={false} stringMode={false} onChange={(e) => onChangeAddExpense(e, "price")} />
            </Form.Item>
          </div>
          <div className="input-content">
            <text className="expense-text">รวม : </text>
            <span>
              {(addExpenseQTY * addExpensePrice).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </span>
          </div>
          <Form.Item>
            <div className="add-expense-footer-content">
              <Button className="add-expense-btn" htmlType="submit">
                เพิ่ม
              </Button>
              <Button className="cancel-expense-btn" onClick={() => onCancelAddExpense()}>
                ยกเลิก
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  function AddItemMd() {
    if (!disabled) {
      return (
        <div className="quo-btn-add-item-wrapper">
          <button type="button" className={`plus-button large quo-btn-add-item d-lg-none mt-3`} onClick={() => onOpenAddItemResponsive(true)}>
            +
          </button>
        </div>
      );
    }
  }

  async function onSubmitMultiselect() {
    setExpanAddModal(false);
    setIsResetMultipleSelect(true);
    await onSubmitMultiSelected(multiSelect);
  }

  function onCancelMultiselect() {
    setIsResetMultipleSelect(true);
    setExpanAddModal(false);
  }
  function onOpenMultiselect() {
    setIsResetMultipleSelect(true);
    setExpanAddModal(true);
  }

  function onOpenAddItemResponsive(bool) {
    setIsOpenAddItemResponsive(bool);
  }

  return (
    <>
      {isOpenAddItemResponsive && DisplayAddItemContainerRsponsive()}
      <section className="item-summary-list-container pb-3 pb-md-4 px-3 px-md-4">
        <Spinner loading={isLoading} />
        {AddItemMd()}
        {/* Header Table Approve Section */}
        <section>
          {DisplayApproveHead()}
          {DisplayResultTable()}
          {DisplayItemList(addList)}
        </section>
        {/* Item Summary Section */}
        <section>{DisplaySummaryContainer()}</section>
        {/* Remark Section */}
        <section>{DisplayRemark()}</section>
        {/* Add Expense Modal */}
        <section>
          {DisplayAddExpenseModal()}
          {DisplayModalExpanAdd()}
          {DisplayResetFeesModal()}
        </section>
      </section>
    </>
  );
}
